<template>
  <div id="app">
    <div class="container is-fluid">
      <div class="columns">
        <div class="column is-offset-one-quarter is-half">
          <div class="intro mb-6 mt-6">
            <h1><AiaibotLogo class="intro--logo" /></h1>
            <p>Are you ready for the aiaibot experience?</p>
          </div>

          <div class="box">
            <div class="connectivity mb-5">
              <h2 class="subtitle">Connectivity</h2>
              <Check
                v-for="check of connectivityChecks"
                :key="check.id"
                :name="check.name"
                :status="check.status"
                :loading="check.loading"
                :additional-info="check.additionalInfo"
              />
            </div>
            <div class="browser-os mb-5">
              <h2 class="subtitle">Browser and Operating System</h2>
              <Value :icon="browserIcon" :name="browserIdentifier" />
              <Value :icon="osIcon" :name="osIdentifier" class="mb-5" />

              <Check
                v-for="check of browserChecks"
                :key="check.id"
                :name="check.name"
                :status="check.status"
                :loading="check.loading"
                :additional-info="check.additionalInfo"
              />
            </div>
            <div class="logs">
              <h2 class="subtitle">Logs</h2>
              <div class="logs--table">
                <table
                  class="table is-bordered is-striped is-narrow is-fullwidth"
                >
                  <tbody>
                    <tr
                      v-for="(log, index) of logs"
                      :key="index"
                      class="is-family-code"
                      :class="logRowClass(log)"
                    >
                      <td>
                        <span
                          v-text="formatTimestamp(log.timestamp)"
                          class="logs--table--timestamp"
                        />
                      </td>
                      <td>
                        <span
                          v-text="log.message"
                          class="logs--table--message"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- Used to copy text to clipboard -->
                <textarea
                  class="logs--table--serialised"
                  ref="serialisedLogs"
                  readonly
                />
              </div>
              <div
                class="logs--action is-flex is-justify-content-flex-end mt-2"
              >
                <button
                  type="button"
                  class="button is-small"
                  @click="copyLogsToClickboard"
                >
                  Copy to Clipboard
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="webchat" ref="webchat" />
  </div>
</template>

<script>
import Bowser from "bowser";
import io from "socket.io-client";
import Check from "@/components/Check";
import Value from "@/components/Value";
import {
  STATUS_PENDING,
  STATUS_FAIL,
  STATUS_SUCCESS
} from "@/models/CheckStatus";
import AiaibotLogo from "@/assets/images/logo-aiaibot.svg";
import BrowserIcon from "@/assets/icons/browser.svg";
import OsIcon from "@/assets/icons/os.svg";

const QUERY_PARAM_API_HOST = "api-host";
const QUERY_PARAM_API_KEY = "api-key";
const QUERY_PARAM_CHAT_HOST = "chat-host";
const QUERY_PARAM_WEBSOCKET_HOST = "ws-host";
const QUERY_PARAM_FILE_STORAGE_HOST = "storage-host";
const QUERY_PARAM_INTEGRATOR_CONFIG_HOST = "integrator-host";
const QUERY_PARAM_SHOW_WEBCHAT = "webchat";

export default {
  components: {
    AiaibotLogo,
    Check,
    Value
  },
  data() {
    const hosts = {
      api: process.env.API_HOST,
      chat: process.env.CHAT_HOST,
      ws: process.env.WEBSOCKET_HOST,
      storage: process.env.FILE_STORAGE_HOST,
      integrator: process.env.INTEGRATOR_CONFIG_HOST
    };

    const apiKey = process.env.API_KEY || "";
    const showWebchat = process.env.SHOW_WEBCHAT;

    return {
      logs: [],
      hosts,
      apiKey,
      checks: {
        connectivity: [
          {
            id: "api",
            name: "REST API",
            check: async () =>
              await this.checkHttpConnection(
                this.hosts.api,
                `/public/v1/configs/${this.apiKey}`
              ),
            loading: false,
            status: STATUS_PENDING,
            additionalInfo: null
          },
          {
            id: "ws",
            name: "Websocket API",
            check: async () =>
              await this.checkWebsocketConnection(
                hosts.ws,
                "/v1/chat",
                "/socketio"
              ),
            loading: false,
            status: STATUS_PENDING,
            additionalInfo: null
          },
          {
            id: "ws-fallback",
            name: "Websocket API (Polling Fallback)",
            check: async () =>
              await this.checkWebsocketConnection(
                this.hosts.ws,
                "/v1/chat",
                "/socketio",
                true
              ),
            loading: false,
            status: STATUS_PENDING,
            additionalInfo:
              "If Websocket is not supported by the browser, or a native Websocket connection could not be established, connections will automatically fall back to HTTP longpolling."
          },
          {
            id: "chat",
            name: "Web Chat",
            check: async () =>
              this.checkHttpConnection(this.hosts.chat, "/bootstrap.js", false),
            loading: false,
            status: STATUS_PENDING,
            additionalInfo: null
          },
          {
            id: "storage",
            name: "File Storage",
            check: async () =>
              await this.checkHttpConnection(this.hosts.storage, "/"),
            loading: false,
            status: STATUS_PENDING,
            additionalInfo: null
          },
          {
            id: "integrator",
            name: "Enterprise Integrations",
            check: async () =>
              await this.checkHttpConnection(
                this.hosts.integrator,
                "/empty.json"
              ),
            loading: false,
            status: STATUS_PENDING,
            additionalInfo: null
          }
        ],
        browser: [
          {
            id: "websocket",
            name: "Websocket supported",
            check: async () => await this.checkWebsocket(),
            loading: false,
            status: STATUS_PENDING,
            additionalInfo: null
          },
          {
            id: "cookie",
            name: "Cookies supported",
            check: async () => await this.checkCookies(),
            loading: false,
            status: STATUS_PENDING,
            additionalInfo: null
          },
          {
            id: "localstorage",
            name: "LocalStorage supported",
            check: async () => await this.checkLocalStorage(),
            loading: false,
            status: STATUS_PENDING,
            additionalInfo: null
          },
          {
            id: "sessionstorage",
            name: "SessionStorage supported",
            check: async () => await this.checkSessionStorage(),
            loading: false,
            status: STATUS_PENDING,
            additionalInfo: null
          }
        ]
      },
      showWebchat
    };
  },
  computed: {
    connectivityChecks() {
      return this.checks.connectivity;
    },
    browserChecks() {
      return this.checks.browser;
    },
    fingerprint() {
      return Bowser.parse(window.navigator.userAgent);
    },
    browserIcon() {
      return BrowserIcon;
    },
    browserIdentifier() {
      if (this.fingerprint) {
        const { browser } = this.fingerprint;
        return `${browser.name} ${browser.version}`;
      }

      return "N/A";
    },
    osIcon() {
      return OsIcon;
    },
    osIdentifier() {
      if (this.fingerprint) {
        const { os } = this.fingerprint;
        return `${os.name} ${os.version}`;
      }

      return "N/A";
    }
  },
  created() {
    // Hook the console.log function to capture console logs
    // https://stackoverflow.com/a/11403146
    const vm = this;
    const builtinLog = console.log;
    const builtinErrorLog = console.error;

    console.log = function(message) {
      if (message) {
        vm.logs.push({ type: "log", timestamp: Date.now(), message });
      }

      builtinLog.apply(console, arguments);
    };

    console.error = function(message) {
      if (message) {
        vm.logs.push({ type: "error", timestamp: Date.now(), message });
      }

      builtinErrorLog.apply(console, arguments);
    };
  },
  async mounted() {
    this.handleConfigurationOverrides();
    await this.doChecks("Connectivity", this.connectivityChecks);
    await this.doChecks("Browser", this.browserChecks);

    if (this.showWebchat) {
      this.setExternalWebchatVariables();
      this.loadWebchat();
    }
  },
  methods: {
    logRowClass(log) {
      if (log.type === "error") {
        return ["has-text-danger", "has-text-weight-semibold"];
      }

      return [];
    },
    formatTimestamp(timestamp) {
      const locale =
        navigator.languages === undefined
          ? navigator.language
          : navigator.languages[0];
      const formatter = new Intl.DateTimeFormat(locale, {
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
      });

      return formatter.format(new Date(timestamp));
    },
    displayConfirmation(message) {
      this.$buefy.snackbar.open(message);
    },
    async copyLogsToClickboard() {
      const data = JSON.stringify(this.logs);

      if (navigator.clipboard) {
        await navigator.clipboard.writeText(data);
      } else {
        const el = this.$refs.serialisedLogs;

        el.value = data;
        el.select();
        document.execCommand("copy");
        el.value = "";
      }

      this.displayConfirmation("Copied to Clipboard.");
    },
    async checkHttpConnection(host, path, cors = true) {
      const url = new URL(host);
      url.pathname = path;

      console.log(`Connecting to ${url}...`);

      try {
        await fetch(url, {
          method: "GET",
          mode: cors ? "cors" : "no-cors",
          headers: {
            "X-aiaibot-client": "readyness-app"
          }
        });

        console.log(`Successfully connected to ${url}`);

        return true;
      } catch (error) {
        console.error(
          `Could not check HTTP connectivity to ${url} due to an error! Error: `
        );
        console.error(error.message);
      }

      return false;
    },
    async checkWebsocketConnection(
      host,
      path = "/v1/chat",
      namespace = "/socketio/",
      polling = false
    ) {
      const url = new URL(host);
      url.pathname = path;

      console.log(
        `Connecting to Websocket${
          polling ? " (with polling)" : ""
        } at ${url}...`
      );

      try {
        const success = await this.connectToWebsocket(
          url.toString(),
          namespace,
          polling
        );

        if (success) {
          console.log(`Successfully connected to Websocket at ${url}`);
        } else {
          console.error(`Failed to connect to Websocket at ${url}!`);
        }

        return success;
      } catch (error) {
        console.error(
          `Could not connect to Websocket due to an error! Error: `
        );
        console.error(error);

        return false;
      }
    },
    async connectToWebsocket(url, namespace = "/socketio", polling = false) {
      return new Promise((resolve, reject) => {
        const transports = polling ? ["polling"] : ["websocket"];
        const socket = io(url, {
          path: namespace,
          transports,
          transportOptions: {
            polling: {
              extraHeaders: {
                "X-aiaibot-Client": "readyness-app"
              }
            }
          },
          autoConnect: false,
          upgrade: true,
          reconnection: true,
          reconnectionAttempts: 3
        });

        socket.on("connect", () => {
          // Disconnect immediately from server
          socket.disconnect();
        });

        socket.on("disconnect", () => {
          resolve(true);
        });

        socket.on("error", error => {
          reject(error);
        });

        socket.connect();
      });
    },
    async checkWebsocket() {
      // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/websockets.js
      try {
        return "WebSocket" in window && window.WebSocket.CLOSING === 2;
      } catch (e) {
        return false;
      }
    },
    async checkCookies() {
      // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/cookies.js
      try {
        // Create cookie
        document.cookie = "cookietest=1";
        const ret = document.cookie.indexOf("cookietest=") !== -1;
        // Delete cookie
        document.cookie = "cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT";
        return ret;
      } catch (e) {
        return false;
      }
    },
    async checkLocalStorage() {
      // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/localstorage.js
      const item = "aiaibot";
      try {
        localStorage.setItem(item, item);
        localStorage.removeItem(item);
        return true;
      } catch (e) {
        return false;
      }
    },
    async checkSessionStorage() {
      // https://github.com/Modernizr/Modernizr/blob/master/feature-detects/storage/sessionstorage.js
      const item = "aiaibot";
      try {
        sessionStorage.setItem(item, item);
        sessionStorage.removeItem(item);
        return true;
      } catch (e) {
        return false;
      }
    },
    handleConfigurationOverrides() {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);

      if (params.has(QUERY_PARAM_API_HOST)) {
        this.hosts.api = params.get(QUERY_PARAM_API_HOST);
      }

      if (params.has(QUERY_PARAM_API_KEY)) {
        this.apiKey = params.get(QUERY_PARAM_API_KEY);
      }

      if (params.has(QUERY_PARAM_CHAT_HOST)) {
        this.hosts.chat = params.get(QUERY_PARAM_CHAT_HOST);
      }

      if (params.has(QUERY_PARAM_WEBSOCKET_HOST)) {
        this.hosts.ws = params.get(QUERY_PARAM_WEBSOCKET_HOST);
      }

      if (params.has(QUERY_PARAM_FILE_STORAGE_HOST)) {
        this.hosts.storage = params.get(QUERY_PARAM_FILE_STORAGE_HOST);
      }

      if (params.has(QUERY_PARAM_INTEGRATOR_CONFIG_HOST)) {
        this.hosts.integrator = params.get(QUERY_PARAM_INTEGRATOR_CONFIG_HOST);
      }

      if (params.has(QUERY_PARAM_SHOW_WEBCHAT)) {
        this.showWebchat = params.get(QUERY_PARAM_SHOW_WEBCHAT) === "true";
      }
    },
    async doChecks(domain, checks = []) {
      for (const check of checks) {
        console.log(`[${domain}] ${check.name}: Running check...`);

        check.loading = true;

        let success = false;
        if (check.check) {
          success = await check.check();
        }

        check.status = success ? STATUS_SUCCESS : STATUS_FAIL;
        check.loading = false;

        console.log(
          `[${domain}] ${check.name}: Check ${
            check.status === STATUS_SUCCESS ? "succeeded" : "failed"
          }`
        );
      }
    },
    setExternalWebchatVariables() {
      // Turn the checks structure into a flat hierarchy where the check kind (domain)
      // is the prefix and the id is the name of the key.
      // eg. connectivity.api => connectivity_api

      const variables = {};
      const domains = Object.keys(this.checks);
      for (const domain of domains) {
        const prefix = domain.replace("-", "_").toLowerCase();
        const domainChecks = this.checks[domain];

        for (const check of domainChecks) {
          const name = check.id.replace("-", "_").toLowerCase();
          const value = check.status === STATUS_SUCCESS ? "yes" : "no";
          const variable = `${prefix}_${name}`;

          variables[variable] = value;
        }
      }

      // Set the browser and OS version
      variables.browser = this.browserIdentifier;
      variables.os = this.osIdentifier;

      // Also set the current url used for the check
      variables.url = window.location.href;

      window.aiaibotVariables = variables;
    },
    loadWebchat() {
      const $el = this.$refs.webchat;
      const url = new URL(this.hosts.chat);
      url.pathname = "/bootstrap.js";

      const script = document.createElement("script");

      script.async = false;
      script.setAttribute("type", "text/javascript");
      script.setAttribute("src", url.toString());
      script.setAttribute("defer", true);
      script.setAttribute("data-aiaibot-key", this.apiKey);

      $el.insertAdjacentElement("beforeend", script);
    }
  }
};
</script>

<style lang="scss" scoped>
$logo-width: 200px;
$logs-table-height: 200px;

.intro {
  color: $white;

  &--logo {
    max-width: $logo-width;
  }
}

.logs {
  &--table {
    max-height: $logs-table-height;
    overflow: scroll;
    overflow-x: hidden;

    &--serialised {
      height: 0;
      border: 0;
    }

    &--timestamp {
      white-space: nowrap;
    }

    &--message {
      white-space: normal;
      word-break: break-all;
    }
  }
}
</style>
