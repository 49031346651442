<template>
  <div class="value is-flex">
    <div class="value--icon">
      <component :is="icon" />
    </div>
    <div class="value--meta">
      <span v-text="name" />
    </div>
    <div class="value--info" v-if="additionalInfo">
      <b-tooltip type="is-dark" multilined>
        <div class="check--info--icon">
          <InfoIcon />
        </div>

        <template v-slot:content>
          <p v-text="additionalInfo" />
        </template>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import InfoIcon from "@/assets/icons/information.svg";

export default {
  components: {
    InfoIcon
  },
  props: {
    icon: {
      type: [Object, Function],
      default: null
    },
    name: {
      type: String,
      required: true
    },
    additionalInfo: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
$status-size: 1.25em;
$icon-size: 1.25em;

.value {
  align-items: center;
  margin-bottom: 0.5em;

  &--icon {
    height: $icon-size;
    width: $icon-size;
    margin-right: 1em;
  }

  &--info {
    height: $status-size;
    width: $status-size;
    margin-left: auto;

    &--icon {
      height: $icon-size;
      width: $icon-size;
    }
  }
}
</style>
