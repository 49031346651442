<template>
  <div class="check is-flex">
    <div class="check--status">
      <div v-if="loading" class="check--status--loading">
        <div class="control is-loading" />
      </div>
      <div v-else class="check--status--icon">
        <component :is="statusIcon" :class="statusIconClasses" />
      </div>
    </div>
    <div class="check--meta">
      <span v-text="name" />
    </div>
    <div class="check--info" v-if="additionalInfo">
      <b-tooltip type="is-dark" multilined>
        <div class="check--info--icon">
          <InfoIcon />
        </div>

        <template v-slot:content>
          <p v-text="additionalInfo" />
        </template>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import SuccessIcon from "@/assets/icons/success.svg";
import FailIcon from "@/assets/icons/fail.svg";
import InfoIcon from "@/assets/icons/information.svg";
import {
  STATUS_PENDING,
  STATUS_SUCCESS,
  STATUS_FAIL
} from "@/models/CheckStatus";

export default {
  components: {
    SuccessIcon,
    FailIcon,
    InfoIcon
  },
  props: {
    checked: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      default: STATUS_PENDING
    },
    name: {
      type: String,
      required: true
    },
    additionalInfo: {
      type: String,
      default: null
    }
  },
  computed: {
    statusIcon() {
      if (this.status === STATUS_SUCCESS) {
        return SuccessIcon;
      } else if (this.status === STATUS_FAIL) {
        return FailIcon;
      }

      return null;
    },
    statusIconClasses() {
      if (this.status === STATUS_SUCCESS) {
        return ["has-text-success"];
      } else if (this.status === STATUS_FAIL) {
        return ["has-text-danger"];
      }

      return [];
    }
  }
};
</script>

<style lang="scss" scoped>
$status-size: 1.25em;
$icon-size: 1.25em;

.check {
  align-items: center;
  margin-bottom: 0.5em;

  &--status {
    height: $status-size;
    width: $status-size;
    margin-right: 1em;

    &--loading {
      .control {
        height: $icon-size;
        width: $icon-size;
        margin-top: 1px;
        margin-left: -1px;

        // Loading spinner
        &::after {
          top: 0;
          right: 0;
          border: 1px solid $grey-700;
          border-right-color: transparent;
          border-top-color: transparent;
        }
      }
    }

    &--icon {
      height: $icon-size;
      width: $icon-size;
    }
  }

  &--info {
    height: $status-size;
    width: $status-size;
    margin-left: auto;

    &--icon {
      height: $icon-size;
      width: $icon-size;
    }
  }
}
</style>
